<!-- 考生成绩表详情 -->
<template>
    <div class="ResultDetails">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">学员信息</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <div class="" style="margin-bottom: ;">
           <button class="btn" type="button" v-for="(item,index) in tabBtn" :key="index"
            @click="toTab(index)" :class="tab==index?'active':''">
               {{item}}
           </button>
        </div>
        <!-- 单据说明 -->
        <div class=""  v-if="tab==0" :key="0">
           <el-form :model="rulform" inline class="form_2" label-width="180px">
               <el-form-item label="学员姓名">
                   <el-input :value="rulform.name" disabled></el-input>
               </el-form-item>
               <el-form-item label="联系电话">
                   <el-input :value="rulform.telephone" disabled></el-input>
               </el-form-item>
               <el-form-item label="身份证号">
                   <el-input :value="rulform.IDcard" disabled></el-input>
               </el-form-item>
               <el-form-item label="科目">
                   <el-input :value="rulform.subject" disabled></el-input>
               </el-form-item>
               <el-form-item label="报考等级">
                   <el-input :value="rulform.grade" disabled></el-input>
               </el-form-item>
               <el-form-item label="提供机构">
                   <el-input :value="rulform.provide" disabled></el-input>
               </el-form-item>
           </el-form>   
        </div>
        <!-- 录入成绩 -->
        <div class=""  v-if="tab==1" :key="1">
            <el-form :model="form" inline class="form_2" label-width="150px">
                <el-form-item label="登记单号">
                    <el-input v-model="form.registerNum" placeholder="请输入登记单号" disabled></el-input>
                </el-form-item>
                <el-form-item label="机构名称">
                    <el-input v-model="form.designation" placeholder="请输入机构名称" disabled></el-input>
                </el-form-item>
                <el-form-item label="学员姓名">
                    <el-input v-model="form.name" placeholder="请输入学员姓名" disabled></el-input>
                </el-form-item>
                <el-form-item label="准考证号">
                    <el-input v-model="form.ticket" placeholder="请输入准考证号" disabled></el-input>
                </el-form-item>
                <el-form-item label="科目名称">
                    <el-input v-model="form.subject" placeholder="请输入科目名称" disabled></el-input>
                </el-form-item>
                <el-form-item label="指导老师">
                    <el-input v-model="form.instructor" placeholder="请输入指导老师" disabled></el-input>
                </el-form-item>
                <el-form-item label="考试等级">
                    <el-input v-model="form.testGrade" placeholder="请输入考试等级" disabled></el-input>
                </el-form-item>
                <el-form-item label="考试日期">
                    <el-date-picker v-model="form.testDate" 
                     style="width: 300px;" type="date" placeholder="请选择考试日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="考试备注">
                    <el-input v-model="form.remarks" placeholder="请输入考试备注" disabled></el-input>
                </el-form-item>
                <el-form-item label="考试分数">
                    <el-input v-model="form.fraction" placeholder="请输入考试分数"></el-input>
                </el-form-item>
                <el-form-item label="考试结论">
                    <div style="width: 300px;">
                        <el-radio-group v-model="form.verdict">
                            <el-radio :label="1">未录入</el-radio>
                            <el-radio :label="2">通过</el-radio>
                            <el-radio :label="3">不通过</el-radio>
                        </el-radio-group>
                    </div>
                </el-form-item>
                <el-form-item label="选择等级">
                    <el-select v-model="form.selectLevel" placeholder="请选择" :clearable="true">
                        <el-option v-for="item in form.choseGradeOpt" :key="item.id" :label="item.level"
                            :value="item.level">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态">
                    <div style="width: 757px;">
                        <el-radio-group v-model="form.condition">
                            <el-radio :label="0">全部</el-radio>
                            <el-radio :label="1">草稿</el-radio>
                            <el-radio :label="2">报考</el-radio>
                            <el-radio :label="3">准考</el-radio>
                            <el-radio :label="4">考试OK</el-radio>
                            <el-radio :label="5">取消</el-radio>
                            <el-radio :label="6">退款取消</el-radio>
                            <el-radio :label="7">延期</el-radio>
                            <el-radio :label="8">缺考</el-radio>
                        </el-radio-group>
                    </div>
                </el-form-item>
            </el-form> 
              <!-- 底部 提交与取消 -->
              <div slot="footer" class="dialog-footer">
                  <el-button style="background-color: #18BC9C;color: #FFFFFF;" 
                   @click="submitForm('form')">提 交</el-button>
                  <el-button class="cancel" @click="resetForm('form')">取 消</el-button>
              </div>
        </div>
        <!-- 安排表 -->
        <div class=""  v-if="tab==2" :key="2">
            <!-- 表格 -->
            <el-table :data="planData" border stripe style="width:97.9%;flex: 1;" height="650">
                <!-- 复选按钮 -->
                <el-table-column type="selection" align="center" width="50">
                </el-table-column>
                <el-table-column type="index" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="" label="安排状态" align="center" width="100">
                </el-table-column>
                <el-table-column prop="" label="安排单号" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" label="学员姓名" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="拼音名" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" label="性别" align="center" width="80">
                </el-table-column>
                <el-table-column prop="" label="出生日期" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" label="国籍" align="center" width="80">
                </el-table-column>
                <el-table-column prop="" label="民族" align="center" width="80">
                </el-table-column>
                <el-table-column prop="" label="学科" align="center" width="120">
                </el-table-column>
                <el-table-column prop="" label="等级" align="center" width="100">
                </el-table-column>
                <el-table-column prop="" label="考场编号" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" label="考场摘要" align="center" width="200">
                </el-table-column>
                <el-table-column prop="" label="场地" align="center" width="200">
                </el-table-column>
                <el-table-column prop="" label="考试日期" align="center" width="150">
                </el-table-column>
                <el-table-column prop="" label="考试时间" align="center" width="200">
                </el-table-column>
                <el-table-column prop="" label="机构名称" align="center" width="200">
                </el-table-column>
                <el-table-column prop="" label="说明" align="center" width="300">
                </el-table-column>
            </el-table> 
        </div>
        <!-- 考试历史 -->
        <div class=""  v-if="tab==3" :key="3">
            <el-table :data="historyData" border stripe style="width:97.9%;flex: 1;" height="650">
                <el-table-column type="index" label="序号" align="center" width="80">
                </el-table-column>
                <el-table-column prop="name" label="学员姓名" align="center" width="120">
                </el-table-column>
                <el-table-column prop="org_name" label="提供机构" align="center" width="200">
                </el-table-column>
                <el-table-column prop="course" label="考试科目" align="center" width="150">
                </el-table-column>
                <el-table-column prop="exam_level" label="等级" align="center" width="100">
                </el-table-column>
                <el-table-column prop="remark" label="考试备注" align="center" width="150">
                </el-table-column>
                <el-table-column prop="text_score" label="成绩" align="center" width="100">
                </el-table-column>
                <el-table-column prop="exam_result" label="成绩等级" align="center" width="120">
                    <template slot-scope="scope">
                        <span v-if="scope.row.exam_result==1">未录入</span>
                        <span v-else-if="scope.row.exam_result==2">
                            {{ scope.row.exam_level }}
                        </span>
                        <span v-else-if="scope.row.exam_result==3"></span>
                    </template>
                </el-table-column>
                <el-table-column prop="exam2_date" label="考试日期" align="center" width="200">
                </el-table-column>
            </el-table> 
        </div>
        <!--分页 -->
        <el-pagination @size-change="handleSizeChange"
         @current-change="handleCurrentChange" :current-page="currentPage"
            :page-sizes="[20, 50, 100, 500, 1000]" :page-size="10" background
            layout="total, sizes, prev, pager, next, jumper" :total="total" v-if="tab!=0&&tab!=1">
        </el-pagination>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                tabBtn:["单据说明","录入成绩","安排表","考试历史"],
                tab:0,//代表下标
                rulform:{//单据说明表单
                    name:"",//学员姓名
                    telephone:"",//联系电话
                    IDcard:"",//身份证号
                    subject:"",//科目
                    grade:"",//报考等级
                    provide:""//提供机构
                },
                form:{//录入成绩表单
                    registerNum:"",//登记单号
                    designation:"",//机构名称
                    name:"",//学员姓名
                    ticket:"",//准考证号
                    subject:"",//科目名称
                    instructor:"",//指导老师
                    testGrade:"",//考试等级
                    testDate:"",//考试日期
                    remarks:"",//考试备注
                    fraction:"",//考试分数
                    selectLevel:"",//选择等级
                    choseGradeOpt:[],//等级选项
                    verdict:1,//考试结论
                    condition:0,//状态
                },
                planData:[],//安排表
                historyData:[],//考试历史
                bool:false,
                currentPage: 1,
                currentLimit: 20, //条数
                total: 0,
            }
        },
        created() {
            this.getList()
            
            // 等级选项
            this.$request({
                url:'/api/level/list',
                method:'POST',
                data:{
                    page:1,
                    limit:100
                }
            }).then(res=>{
                console.log(res,'等级选项')
                this.form.choseGradeOpt=res.data.list
            })
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            }, 
            toTab(index){
                this.tab=index
                this.getList()
            },
            getList(){
                if(this.tab==0){//单据说明
                    this.$request({url:'/api/examenroll/description',method:'POST',data:{
                        id:this.$route.query.id
                    }}).then(res=>{
                        // console.log(res)
                        let data = res.data
                        if(res.code==1){
                           this.rulform.name=data.name//学员姓名
                           this.rulform.telephone=data.phone//联系电话
                           this.rulform.IDcard=data.idcard//身份证号
                           this.rulform.subject=data.course //科目
                           this.rulform.grade=data.exam_level //报考等级
                           this.rulform.provide=data.org_name //提供机构
                        }
                    })
                }else if(this.tab==1){//录入成绩
                    this.$request({
                        url:'/api/examenroll/detail',method:'POST',data:{
                            id:this.$route.query.id
                        }
                    }).then(res=>{
                        console.log(res,'录入成绩')
                        let data = res.data
                        let form =  this.form
                        form.registerNum=data.enroll_number//登记单号
                        form.designation=data.org_name//机构名称
                        form.name=data.name//学员姓名
                        form.ticket=data.certificate_no//准考证号
                        form.subject=data.course//科目名称
                        form.instructor=data.teacher//指导老师
                        form.testGrade=data.exam_level//考试等级
                        form.testDate=data.exam2_date//考试日期
                        form.remarks=data.remark//考试备注
                        form.fraction=data.text_score//考试分数
                        form.verdict=data.exam_result//考试结论
                        // form.choseGrade=data.//选择等级
                        form.condition=data.status//状态 
                    })
                }else if(this.tab==3){//安排表
                    // this.$request({url:'',method:'POST',data:{
                        
                    // }}).then(res=>{
                    //     console.log(res)
                    //     if(res.code==1){
                             
                    //     }
                    // })
                }else if(this.tab==4){//考试历史
                    this.$request({url:'/api/examenroll/history',method:'POST',data:{
                        idcard:this.rulform.IDcard,
                        // page:this.currentPage,
                        // limit:this.currentLimit
                    }}).then(res=>{
                        console.log(res)
                        if(res.code==1){
                             this.historyData=res.data.list
                             this.total=res.data.count
                        }
                    })
                }
            },
            handleSizeChange(val) {
                this.currentLimit=val
                this.getList()
                // console.log(`每页 ${val} 条`);
            },
            handleCurrentChange(val) {
                this.currentPage=val
                this.getList()
                // console.log(`当前页: ${val}`);
            },
            submitForm(form) {//提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        // console.log(this.form)
                        if(this.bool){
                            return
                        }   
                        this.bool=true
                        this.$request({
                            url: '/api/examenroll/edit',
                            method: 'POST',
                            data:{
                                id:this.$route.query.id,
                                original_level:this.form.selectLevel,//等级
                                text_score:this.form.fraction,//分数
                                exam_result:this.form.verdict,//结论
                                status:this.form.condition//状态
                            }
                        }).then(res=>{
                            if(res.code==1){
                                this.$message({
                                    message: '提交成功',
                                    type: 'success'
                                })
                                setTimeout(() => {
                                    this.goBack()
                                }, 1500)
                            }else{
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })
                                setTimeout(() => {
                                    this.goBack()
                                }, 1500)
                            }
                        }).catch(()=>{
                            this.bool=false
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) {//取消
                this.goBack() 
                this.$refs[form].resetFields();
            },
        }
    }
</script>

<style scoped="scoped">
    .ResultDetails{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 39px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* tab按钮 */
    .btn{
       display: inline-block;
       border: 1px solid #999999;
       background-color: #FFFFFF;
       padding: 5px 15px;
       margin:0px 22px ;
       font-size: 14px;
       border-radius: 3px;
       cursor: pointer;
    }
    /* 选中样式 */
    .active{
        background: #26716C;
        color: #fff;
    }
    /* 单据说明 */
    .form_2{
        margin-top: 30px;
       display: flex;
       justify-content: start;
       flex-wrap: wrap;
    }
    .form_2::v-deep .el-input__inner {
        width: 300px;
        height: 32px;
        border:1px solid #ccc;
        background-color: #ffff;
        color: #000000;
    }
    .form_2::v-deep .el-form-item__label {
        color: #000000;
    }
    ::v-deep .el-radio__input.is-checked .el-radio__inner {
        background-color: #18BC9C;
    
    }
    
    ::v-deep .el-radio__input.is-checked+.el-radio__label {
        color: #18BC9C;
    }
    
    ::v-deep .el-radio__inner:hover {
        border-color: #18BC9C;
    }
    /* 安排表 表格 */
    .el-table {
        margin: 20px;
        
    }
    
    ::v-deep .el-table__cell {
        color: #000;
        padding: 0;
        height: 35px;
    }
    /* 分页 */
    .el-pagination {
        margin: 20px;
        margin-top: 0px;
    }
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #2C3E50 !important;
    }
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        margin-top: 50px;
        padding-bottom: 20px;
    }
    .cancel:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
